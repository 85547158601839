import React from "react";
import {
  ShimmerCircularImage,
  ShimmerSectionHeader,
} from "react-shimmer-effects";
import "./comment_shimmer.module.scss";

const CommentShimmer = () => {
  return (
    <div className="d-flex flex-row gap-2">
      <ShimmerCircularImage size={50} />
      <ShimmerSectionHeader />
    </div>
  );
};

export default CommentShimmer;
