import React from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import BeatLoader from "react-spinners/BeatLoader";
import { Button, Input, SubTitle } from "../../Components/CustomComponents";
import { useSentNotificationMutation } from "../../Data/services/notificationApi";
import Toast, { SuccessToast } from "../../Hooks/useToast";
import styles from "./styles/sendNotificationScreen.module.scss";

const SendNotificationScreen = ({ setPage }) => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({});

  const [sendNotification, { isLoading }] = useSentNotificationMutation();

  // const [title, setTitle] = useState("");
  // const [notification, setNotification] = useState("");

  const handleSendNotification = async (data) => {
    try {
      const response = await sendNotification(data);

      if (response.data.code === 200) {
        SuccessToast(response?.data.message);
        reset();
      }
    } catch (error) {
      if (error?.response && error?.response?.data?.message) {
        Toast(error?.response?.data?.message, "error");
      } else {
        Toast("An error occurred during login", "error");
      }
    }
  };

  return (
    <div className={styles.MainContainer}>
      {errors
        ? errors.title?.message
          ? Toast(errors.title?.message, "error")
          : Toast(errors.notification?.message, "error")
        : null}

      <div className={styles.headerContainer}>
        <div>
          <SubTitle text={"Send Notification"} classes="primary-text" />
        </div>
        <Row>
          <Col md={12}>
            <Button
              classes={`${styles.cardBtn} ${isLoading ? "bg-dark-subtle" : ""}`}
              text={isLoading ? <BeatLoader size={10} /> : "Send"}
              onClick={handleSubmit(handleSendNotification)}
              disabled={isLoading}
            ></Button>
          </Col>
        </Row>
      </div>

      <div className="mt-3">
        <Form>
          <Controller
            control={control}
            name="title"
            rules={{
              required: "Title is required.",
            }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <InputGroup className="mb-3">
                <Input
                  placeholder="Title"
                  type="text"
                  value={value}
                  onChange={onChange}
                />
              </InputGroup>
            )}
          />

          <Controller
            control={control}
            name="notification"
            rules={{
              required: "Notification Message is required.",
            }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <InputGroup className="mb-3">
                <Input
                  placeholder="Notification"
                  type="textarea"
                  as="textarea"
                  rows={5}
                  value={value}
                  onChange={onChange}
                />
              </InputGroup>
            )}
          />
        </Form>
      </div>
    </div>
  );
};

export default SendNotificationScreen;
