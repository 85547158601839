import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { FaCircleXmark } from "react-icons/fa6";
import BeatLoader from "react-spinners/BeatLoader";
import { Button } from "../CustomComponents";
import SelectFieldComponent from "../CustomComponents/SelectFieldComponent";
import ModalContainer from "../ModalContainer";
import cssStyles from "./styles/editModal.module.scss";

const EditUserModal = ({
  handleEditModalClose,
  data,
  handleUpdate,
  updateLoading,
}) => {
  const { handleSubmit, control } = useForm({});

  const [rowId, setRowId] = useState("");
  const [userSlug, setUserSlug] = useState("");
  // const [userName, setUserName] = useState("");
  // const [userEmail, setUserEmail] = useState("");
  // const [password, setPassword] = useState("");

  // const [selectedRole, setSelectedRole] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");

  // const roleList = [
  //   { label: "Admin", value: "admin" },
  //   { label: "Moderator", value: "moderator" },
  //   { label: "Editor", value: "editor" },
  // ];

  const statusList = [
    { label: "Active", value: "active" },
    { label: "Block", value: "block" },
  ];

  const [mounted, setMounted] = React.useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (data) {
      setUserSlug(data.slug);
      if (data.status === true) {
        setSelectedStatus("Active");
      } else {
        setSelectedStatus("Block");
      }
    }
  }, [data, mounted]);

  const handleStatusChange = (value) => {
    console.log(value);
    setSelectedStatus(value);
  };

  // const handleSubmit = (e) => {
  //   console.log(userName);
  //   console.log(userEmail);
  //   console.log(password);
  //   console.log(selectedRole);
  // };

  if (!mounted) {
    return;
  }

  return (
    <div>
      <ModalContainer handleClose={handleEditModalClose}>
        <div className={cssStyles.modalContainer}>
          <div className="d-flex align-items-center w-100 justify-content-between mb-4">
            <p className="fs-4 fw-medium m-0 black-text">Edit User</p>
            <span className="bg-dark bg-opacity-75 d-flex p-3 rounded-5">
              <FaCircleXmark
                role="button"
                onClick={handleEditModalClose}
                style={{ cursor: "pointer" }}
              />
            </span>
          </div>
          <div className="w-100">
            <p className="text-body-tertiary">{data.name}</p>
            <Form onSubmit={handleSubmit(handleUpdate)}>
              <Row>
                <Col md={12}>
                  <p className="m-0 black-text">Status</p>
                  <Controller
                    control={control}
                    name="status"
                    rules={{}}
                    defaultValue={selectedStatus}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <SelectFieldComponent
                        firstOption={
                          _.isEmpty(selectedStatus)
                            ? "Select Status"
                            : selectedStatus
                        }
                        optionsList={statusList}
                        onSelectChange={onChange}
                      />
                    )}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Button
                    classes={`${cssStyles.cardBtn} mt-4 ${
                      updateLoading ? "bg-dark-subtle" : ""
                    }`}
                    text={updateLoading ? <BeatLoader size={10} /> : "Update"}
                    // onClick={handleSubmit}
                    type="Submit"
                  ></Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </ModalContainer>
    </div>
  );
};

export default EditUserModal;
