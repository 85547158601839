import React, { useEffect, useState } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { ShimmerTable } from "react-shimmer-effects";
import { SubTitle } from "../../Components/CustomComponents";
import styles from "./styles/user_management.module.scss";

//
import { useDispatch, useSelector } from "react-redux"; // for getting and setting data into local redux
import Table from "../../Components/Table/Table";
import { useGetSubscriptionDetailsQuery } from "../../Data/services/subscriptionApi";
// import Toast from "../../Hooks/useToast";

const SubscriptionManagementScreen = ({ setPage }) => {
  const dispatch = useDispatch();

  const [indicationMessage, setIndicationMessage] = useState("loading...");
  const [rowData, setRowData] = useState([]);

  // const rowData = [
  //   {
  //     id: 1,
  //     user: "User name",
  //     type: "one-time",
  //     status: "0",
  //     amount: "$1.99",
  //   },
  //   {
  //     id: 2,
  //     user: "User name",
  //     type: "one-time",
  //     status: "1",
  //     amount: "$1.99",
  //   },
  //   {
  //     id: 3,
  //     user: "User name",
  //     type: "one-time",
  //     status: "1",
  //     amount: "$1.99",
  //   },
  //   {
  //     id: 4,
  //     user: "User name",
  //     type: "one-time",
  //     status: "0",
  //     amount: "$1.99",
  //   },
  //   {
  //     id: 5,
  //     user: "User name",
  //     type: "one-time",
  //     status: "1",
  //     amount: "$1.99",
  //   },
  // ];

  const { data, isSuccess, isLoading, isFetching, refetch } =
    useGetSubscriptionDetailsQuery({ refetchOnMountOrArgChange: true });

  var postData = useSelector((state) => state.subscription.data);

  useEffect(() => {
    setRowData(postData);
  }, [postData]);

  // useEffect(() => {
  //   if (data && isSuccess) {
  //     dispatch(updatePosts(data.data));
  //   }
  // }, [isSuccess, data, dispatch]);

  function indication() {
    // return something here
    // return 'Table is empty';
    return <ShimmerTable row={5} col={4} />;
  }

  function checkAndConvertToUpper(str) {
    let modifiedStr = str.replace(/_/g, " ");

    // Convert to uppercase if it's not already
    if (modifiedStr !== modifiedStr.toUpperCase()) {
      return modifiedStr.toUpperCase();
    }
    return modifiedStr;
  }

  const columns = [
    {
      dataField: "name",
      text: "User Name",
      // sort: true,
      headerStyle: {
        width: "200px",
      },
      // formatter: (cellContent, row) => {
      //   return <img alt="post" src={row.image_url} width={"150"} />;
      // },
    },
    {
      dataField: "type",
      text: "Subscription type",
      // sort: true,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "200px",
      },
    },
    {
      dataField: "amount",
      text: "Subscription Amount",
      // sort: true,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "200px",
      },
      formatter: (cellContent, row) => {
        return "$" + row.amount;
      },
    },
    {
      dataField: "status",
      text: "Subscription Status",
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "200px",
      },
      formatter: (cellContent, row) => {
        // console.log(row)
        if (row.subscriber_id) {
          const status = checkAndConvertToUpper(row.status);
          // console.log(row.status);
          // console.log(status);

          return (
            <div className="d-flex justify-content-center align-items-center ">
              <div
                className={`d-flex justify-content-center align-items-center white-text rounded-3 mx-2 p-2 ${
                  status === "EXPIRED" ? "bg-danger" : "bg-warning"
                } `}
                role="button"
                onClick={() => {}}
              >
                <span>{status}</span>
              </div>
            </div>
          );
        }
      },
    },
  ];

  return (
    <div className={styles.MainContainer}>
      <div className={styles.headerContainer}>
        <div>
          <SubTitle text={"Subscription Management"} classes="primary-text" />
        </div>
        {
          // <Row>
          //   <Col md={12}>
          //     <Button
          //       classes={`${styles.cardBtn} `}
          //       text="Add Event"
          //       onClick={handleAddUser}
          //     ></Button>
          //   </Col>
          // </Row>
        }
      </div>

      <div className={styles.mainTable}>
        {
          // <Table data={rowData} columns={columns} indication={indication} />
          <Table
            data={rowData ?? []}
            columns={columns}
            indication={indication}
          />
        }
      </div>
    </div>
  );
};

export default SubscriptionManagementScreen;
