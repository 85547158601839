import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { FaUserEdit } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import { SubTitle } from "../../Components/CustomComponents";
import DeleteModal from "../../Components/Modals/DeleteModal";
import EditUserModal from "../../Components/Modals/EditUserModal";
import Table from "../../Components/Table/Table";
import Toast, { SuccessToast } from "../../Hooks/useToast";
import styles from "./styles/user_management.module.scss";

// import { ToastContainer, toast } from "react-toastify";

//
import { useDispatch, useSelector } from "react-redux"; // for getting and setting data into local redux
import { ShimmerTable } from "react-shimmer-effects";
import {
  useDeleteUserMutation,
  useGetAllUsersQuery,
  useUpdateUserMutation,
} from "../../Data/services/userApi"; // get data from API Servers

const UserManagementScreen = ({ setPage }) => {
  const dispatch = useDispatch();

  // const [data, setData] = useState([]);
  const [indicationMessage, setIndicationMessage] = useState("loading...");
  const [rowData, setRowData] = useState([]);
  const [editedData, setEditedData] = useState("");

  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const { data, isSuccess, isLoading, isFetching, refetch } =
    useGetAllUsersQuery({ refetchOnMountOrArgChange: true });

  var userData = useSelector((state) => state.user.data);
  // console.log(`userData = ${userData}`);
  useEffect(() => {
    setRowData(userData);
  }, [userData]);

  const handleDeleteModalOpen = () => {
    setDeleteModal(true);
  };

  const handleDeleteModalClose = () => {
    setDeleteModal(false);
    setEditedData("");
  };

  const handleEditModalOpen = () => {
    setEditModal(true);
  };

  const handleEditModalClose = () => {
    setEditModal(false);
    setEditedData("");
  };

  const handleAddUser = () => {
    setEditModal(true);
  };

  const [deleteUser, { isLoading: isDeleting }] = useDeleteUserMutation();

  const deleteData = async (slug) => {
    console.log(editedData.slug);

    const userSlug = editedData.slug;

    try {
      const { data } = await deleteUser(userSlug);

      console.log(data);

      if (data.code === 200) {
        await refetch();
        handleDeleteModalClose();
        SuccessToast(data.message);
      }
    } catch (error) {
      console.log(error);
      Toast(error.message, "error");
    }
  };

  const [updateUser, { isLoading: updateLoading }] = useUpdateUserMutation();

  const handleUpdate = async (formData) => {
    const userSlug = editedData.slug;

    const modifiedData = {
      status: formData.status === "active" ? true : false,
      slug: userSlug,
    };

    console.log(modifiedData);

    try {
      const { data } = await updateUser(modifiedData);
      console.log(data);

      if (data.code === 200) {
        await refetch();
        handleEditModalClose();
        SuccessToast(data.message);
      }
    } catch (error) {
      console.log(error);
      Toast(error.message, "error");
    }
  };

  function indication() {
    // return something here
    // return 'Table is empty';
    return <ShimmerTable row={5} col={4} />;
  }

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
      headerStyle: {
        width: "200px",
      },
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      headerStyle: {
        width: "300px",
      },
    },
    // {
    //   dataField: "role",
    //   text: "Roles",
    //   sort: true,
    //   headerAlign: "center",
    //   align: "center",
    //   headerStyle: {
    //     width: "100px",
    //   },
    //   formatter: (cellContent, row) => {
    //     if (row.id === 1) return <span> SuperAdmin </span>;
    //     if (row.id === 2) return <span>Admin</span>;
    //     if (row.id >= 3) return <span>Moderator</span>;
    //   },
    // },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "100px",
      },
      formatter: (cellContent, row) => {
        if (row.status) {
          return <div className={styles.active}> Active </div>;
        } else {
          return <div className={styles.block}>Block</div>;
        }
      },
    },
    {
      dataField: "actions",
      text: "Actions",
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "200px",
      },
      formatter: (cellContent, row) => {
        // console.log(row)
        if (row.slug) {
          return (
            <div className="d-flex justify-content-center align-items-center ">
              <div
                className={`${styles.btnBg} d-flex justify-content-center align-items-center white-text rounded-3 mx-2 p-2 `}
                role="button"
                onClick={() => {
                  setEditedData(row);
                  handleEditModalOpen(true);
                }}
              >
                <span
                  className={`align-items-center d-flex justify-content-center pe-1 `}
                >
                  <FaUserEdit />
                </span>
                <span>Edit</span>
              </div>
              <div
                className="d-flex justify-content-center align-items-center bg-danger white-text rounded-3 p-2 mx-2 "
                role="button"
                onClick={() => {
                  setEditedData(row);
                  handleDeleteModalOpen();
                }}
              >
                <span className={`d-flex align-items-center pe-1`}>
                  <MdDeleteForever />
                </span>
                <span>Delete</span>
              </div>
            </div>
          );
        }
      },
    },
  ];

  return (
    <div className={styles.MainContainer}>
      {deleteModal ? (
        <DeleteModal
          handleDeleteModalClose={handleDeleteModalClose}
          data={editedData}
          onDelete={deleteData}
          isDeleting={isDeleting}
        />
      ) : (
        ""
      )}

      {editModal ? (
        <EditUserModal
          handleEditModalClose={handleEditModalClose}
          data={editedData}
          handleUpdate={handleUpdate}
          updateLoading={updateLoading}
        />
      ) : (
        ""
      )}

      <div className={styles.headerContainer}>
        <div>
          <SubTitle text={"User Management"} classes="primary-text" />
        </div>
        <Row>
          <Col md={12}>
            {
              //   <Button
              //   classes={`${styles.cardBtn} `}
              //   text="Add User"
              //   onClick={handleAddUser}
              // ></Button>
            }
          </Col>
        </Row>
      </div>

      <div className={styles.mainTable}>
        {
          <Table
            data={rowData ?? []}
            columns={columns}
            indication={indication}
          />
        }
      </div>
    </div>
  );
};

export default UserManagementScreen;
